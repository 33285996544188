//#region CLASS

export class NumberConstant
{
	//#region USER INTERFACE

	static NUMBER_PRODUCTASRI_GALLERY_SIZE: number = 5;
	static NUMBER_PRODUCTOTO_GALLERY_SIZE: number = 9;

	static NUMBER_VALUE_THEME_SECONDARY_DARK_RED: number = 242;
	static NUMBER_VALUE_THEME_SECONDARY_DARK_GREEN: number = 111;
	static NUMBER_VALUE_THEME_SECONDARY_DARK_BLUE: number = 33;

	static NUMBER_VALUE_THEME_PRIMARY_DARK_RED: number = 40;
	static NUMBER_VALUE_THEME_PRIMARY_DARK_GREEN: number = 118;
	static NUMBER_VALUE_THEME_PRIMARY_DARK_BLUE: number = 188;

	static NUMBER_VALUE_THEME_CHART_STEP_RED: number = 20;
	static NUMBER_VALUE_THEME_CHART_STEP_GREEN: number = 15;
	static NUMBER_VALUE_THEME_CHART_STEP_BLUE: number = 3;

	static NUMBER_VALUE_THEME_PRIMARY_CHART_STEP_RED: number = 5;
	static NUMBER_VALUE_THEME_PRIMARY_CHART_STEP_GREEN: number = 15;
	static NUMBER_VALUE_THEME_PRIMARY_CHART_STEP_BLUE: number = 15;

	static NUMBER_VALUE_DECIMAL_PREVIEW: number = 2;

	static NUMBER_VALUE_ZERO: number = 0;
	static NUMBER_VALUE_ONE: number = 1;

	static NUMBER_RISKLIBRARY_ACTIVITY_COLUMN_POSITION: number = 0;
	static NUMBER_RISKLIBRARY_SUBACTIVITY_COLUMN_POSITION: number = 1;
	static NUMBER_RISKLIBRARY_RISKISSUE_COLUMN_POSITION: number = 2;
	//#endregion
}

//#endregion