//#region IMPORT

import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgSelectModule } from "@ng-select/ng-select";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { HomeComponent } from "./components/home/home.component";
import { TableControlIncludeComponent } from "./components/includes/tablecontrol.include/tablecontrol.include.component";
import { AppComponent } from "./components/root/app.component";
import { SigninComponent } from "./components/signin/signin.component";
import { TicketdetailComponent } from "./components/tickets/ticketdetail/ticketdetail.component";
import { TicketlistinboxComponent } from "./components/tickets/ticketlistinbox/ticketlistinbox.component";
import { TicketlistoutboxComponent } from "./components/tickets/ticketlistoutbox/ticketlistoutbox.component";
import { PipeModule } from "./functions/pipes/pipe.module";
import { DownloadComponent } from "./components/download/download.component";
import { PwaService } from "./services/pwa.service";
import { DashboardComponent } from "./components/dashboards/dashboard/dashboard.component";
import { ApprovalReimbursementListComponent } from "./components/approvalreimbursement/approvalreimbursementlist/approvalreimbursementlist.component";
import { DentalreimbursementdetailComponent } from "./components/approvalreimbursement/dentalreimbursementdetail/dentalreimbursementdetail.component";
import { MaternityreimbursementdetailComponent } from "./components/approvalreimbursement/maternityreimbursementdetail/maternityreimbursementdetail.component";
import { OpticreimbursementdetailComponent } from "./components/approvalreimbursement/opticreimbursementdetail/opticreimbursementdetail.component";
import { PostmaternityreimbursementdetailComponent } from "./components/approvalreimbursement/postmaternityreimbursementdetail/postmaternityreimbursementdetail.component";
import { PrematernityreimbursementdetailComponent } from "./components/approvalreimbursement/prematernityreimbursementdetail/prematernityreimbursementdetail.component";
import { AssessmentlistComponent } from "./components/rcsa/assessment/assessmentlist/assessmentlist.component";
import { AssessmentdetailComponent } from "./components/rcsa/assessment/assessmentdetail/assessmentdetail.component";
import { ApprovallistComponent } from "./components/rcsa/approval/approvallist/approvallist.component";
import { ApprovaldetailComponent } from "./components/rcsa/approval/approvaldetail/approvaldetail.component";
import { ReportlistComponent } from "./components/rcsa/report/reportlist/reportlist.component";
import { ReportdetailComponent } from "./components/rcsa/report/reportdetail/reportdetail.component";
import { RiskissuerequestlistComponent } from "./components/rcsa/riskissuerequest/riskissuerequestlist/riskissuerequestlist.component";
import { RiskissuerequestdetailComponent } from "./components/rcsa/riskissuerequest/riskissuerequestdetail/riskissuerequestdetail.component";
import { SubmissionListComponent } from "./components/digitalization/submissions/submissionlist/submissionlist.component";
import { SubmissionDetailComponent } from "./components/digitalization/submissions/submissiondetail/submissiondetail.component";
import { SubmissionInsertComponent } from "./components/digitalization/submissions/submissioninsert/submissioninsert.component";
import { CheckDevToolsDirective } from "./components/bases/basecheckdevtools.directive";
import { FollowupplandetailComponent } from "./components/ras/followupplan/followupplandetail/followupplandetail.component";
import { FollowupplanlistComponent } from "./components/ras/followupplan/followupplanlist/followupplanlist.component";
import { EmateraicheckersignerlistComponent } from "./components/ematerai/emateraicheckersignerlist/emateraicheckersignerlist.component";
import { EmateraicheckersignerdetailComponent } from "./components/ematerai/emateraicheckersignerdetail/emateraicheckersignerdetail.component";
import { EmateraimakerlistComponent } from "./components/ematerai/emateraimakerlist/emateraimakerlist.component";
import { EmateraimakerdetailComponent } from "./components/ematerai/emateraimakerdetail/emateraimakerdetail.component";
import { DashboardrasdetailComponent } from "./components/ras/dashboardras/dashboardrasdetail/dashboardrasdetail.component";
import { DashboardraslistComponent } from "./components/ras/dashboardras/dashboardraslist/dashboardraslist.component";
import { AnnualdisclosurecheckersignerdetailComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurecheckersignerdetail/annualdisclosurecheckersignerdetail.component";
import { AnnualdisclosurecheckersignerlistComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurecheckersignerlist/annualdisclosurecheckersignerlist.component";
import { AnnualdisclosuredetailComponent } from "./components/gcgonline/annualdisclosure/annualdisclosuredetail/annualdisclosuredetail.component";
import { AnnualdisclosureinsertComponent } from "./components/gcgonline/annualdisclosure/annualdisclosureinsert/annualdisclosureinsert.component";
import { AnnualdisclosurelistComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurelist/annualdisclosurelist.component";
import { CodeofethicscheckersignerdetailComponent } from "./components/gcgonline/codeofethics/codeofethicscheckersignerdetail/codeofethicscheckersignerdetail.component";
import { CodeofethicscheckersignerlistComponent } from "./components/gcgonline/codeofethics/codeofethicscheckersignerlist/codeofethicscheckersignerlist.component";
import { CodeofethicsdetailComponent } from "./components/gcgonline/codeofethics/codeofethicsdetail/codeofethicsdetail.component";
import { CodeofethicsinsertComponent } from "./components/gcgonline/codeofethics/codeofethicsinsert/codeofethicsinsert.component";
import { CodeofethicslistComponent } from "./components/gcgonline/codeofethics/codeofethicslist/codeofethicslist.component";
import { GratificationdetailComponent } from "./components/gcgonline/gratification/gratificationdetail/gratificationdetail.component";
import { GratificationlistComponent } from "./components/gcgonline/gratification/gratificationlist/gratificationlist.component";
import { IntegritypactdetailComponent } from "./components/gcgonline/integritypact/integritypactdetail/integritypactdetail.component";
import { IntegritypactinsertComponent } from "./components/gcgonline/integritypact/integritypactinsert/integritypactinsert.component";
import { IntegritypactlistComponent } from "./components/gcgonline/integritypact/integritypactlist/integritypactlist.component";
import { ProfilegcgonlineComponent } from "./components/gcgonline/profilegcgonline/profilegcgonline.component";
import { TicketReportComponent } from "./components/tickets/ticketreport/ticketreport/ticketreport.component";
import { TicketSatisfactionComponent } from "./components/tickets/ticketsatisfaction/ticketsatisfaction.component";
import { TicketReportSatisfactionComponent } from "./components/tickets/ticketreport/ticketreportsatisfaction/ticketreportsatisfaction.component";

//#endregion


//#region MODULE

const initializer = (pwaService: PwaService) => () => pwaService.setupInstaller();

@NgModule
(
	{
		declarations:
		[
			AppComponent,
			SigninComponent,
			HomeComponent,
			DashboardComponent,
			TableControlIncludeComponent,
			TicketlistinboxComponent,
			TicketlistoutboxComponent,
			TicketdetailComponent,
			TicketSatisfactionComponent,
			TicketReportComponent,
			TicketReportSatisfactionComponent,
			DownloadComponent,
			ApprovalReimbursementListComponent,
			DentalreimbursementdetailComponent,
			OpticreimbursementdetailComponent,
			PostmaternityreimbursementdetailComponent,
			PrematernityreimbursementdetailComponent,
			MaternityreimbursementdetailComponent,
			AssessmentlistComponent,
			AssessmentdetailComponent,
			ApprovallistComponent,
			ApprovaldetailComponent,
			ReportlistComponent,
			ReportdetailComponent,
			RiskissuerequestlistComponent,
			RiskissuerequestdetailComponent,
			SubmissionInsertComponent,
			SubmissionListComponent,
			SubmissionDetailComponent,
			CheckDevToolsDirective,
			DashboardraslistComponent,
			DashboardrasdetailComponent,
			FollowupplanlistComponent,
			FollowupplandetailComponent,
			EmateraicheckersignerlistComponent,
			EmateraicheckersignerdetailComponent,
			EmateraimakerlistComponent,
			EmateraimakerdetailComponent,
			ProfilegcgonlineComponent,
			GratificationlistComponent,
			GratificationdetailComponent,
			IntegritypactlistComponent,
			IntegritypactinsertComponent,
			IntegritypactdetailComponent,
			CodeofethicslistComponent,
			CodeofethicsinsertComponent,
			CodeofethicsdetailComponent,
			CodeofethicscheckersignerlistComponent,
			CodeofethicscheckersignerdetailComponent,
			AnnualdisclosurelistComponent,
			AnnualdisclosureinsertComponent,
			AnnualdisclosuredetailComponent,
			AnnualdisclosurecheckersignerlistComponent,
			AnnualdisclosurecheckersignerdetailComponent,
		],
		imports:
		[
			BrowserModule,
			AppRoutingModule,
			FormsModule,
			HttpClientModule,
			PipeModule,
			BrowserAnimationsModule,
			NgxChartsModule,
			MatNativeDateModule,
			MatInputModule,
			NgSelectModule,
			MatDatepickerModule,
			PdfViewerModule,
			DragDropModule,
			ServiceWorkerModule.register("ngsw-worker.js", {
				enabled: environment.serviceWorker,
				// Register the ServiceWorker as soon as the app is stable
				// or after 30 seconds (whichever comes first).
				registrationStrategy: "registerWhenStable:30000"
			})
		],
		providers:
		[
			{
				provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true
			}
		],
		bootstrap: [AppComponent]
	}
)

//#endregion


//#region CLASS

export class AppModule { }

//#endregion