//#region IMPORT

import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";
import { CryptographyFunction } from "src/app/functions/cryptography.function";
import { RequestModel } from "src/app/models/request.model";
import { ResponseModel } from "src/app/models/response.model";
import { ENUM_HTTP_STATUS, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { HandshakeModel } from "src/app/models/handshake.model";
import { StringConstant } from "src/app/constants/string.constant";
import { HandshakeServiceInterface } from "src/app/interfaces/handshake.service.interface";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { GuardServiceInterface } from "src/app/interfaces/guard.service.interface";
import { UserModel } from "src/app/models/user.model";
import { SessionService } from "../session.service";
import { GeneralServiceInterface } from "../../interfaces/general.service.interface";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class BaseService
{
	//#region DECLARATION

	protected _objectHTTPOptions = {};
	protected _clientHTTP: HttpClient;

	protected _functionCryptography: CryptographyFunction;

	protected _serviceSession: SessionService;

	private _modelHandshake: HandshakeModel;
	private _modelUser: UserModel;

	//#endregion


	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		this._clientHTTP = clientHTTP;

		this._functionCryptography = new CryptographyFunction();

		this._serviceSession = serviceSession;

		this._modelHandshake = new HandshakeModel();
		this._modelUser = new UserModel();

		this._serviceSession._modelHandshakeSignIn.subscribe(
			{
				next: (modelHandshake: HandshakeModel) => this._modelHandshake = modelHandshake,
				complete: () => { },
				error: () => { }
			});

		this._serviceSession._modelUserSignIn.subscribe(
			{
				next: (modelUser: UserModel) => this._modelUser = modelUser,
				complete: () => { },
				error: () => { }
			}
		);
	}

	//#endregion


	//#region CALL

	/*
		callServiceHandshake - START
		Description : Default first handshake call service, return as response model.
		Author : Ibrahim Aziz.
		Created on : Tuesday, 23 April 2019.			Updated on : Wednesday, 6 January 2021.
		Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
		Version : 1.0:5.
		References : https://www.freakyjolly.com/angular-7-6-use-auth-guards-canactivate-and-resolve-in-angular-routing-quick-example/.
	*/

	protected callServiceHandshake(interfaceHandshakeService: HandshakeServiceInterface, stringData: string, stringURL: string): Observable<boolean>
	{
		this._objectHTTPOptions =
		{
			headers: new HttpHeaders
			({
				"Content-Type": "application/json; charset=utf-8"
				// "X-Frame-Options" : "DENY",
			}),
			observe: "response",
			responseType: "text"
		};

		try
		{
			const modelRequest: RequestModel = new RequestModel();
			modelRequest.setRequest(this._modelUser.ID);
			modelRequest.Data = stringData;
			const stringRequest: string = "\"" + btoa(JSON.stringify(modelRequest)) + "\"";

			return this._clientHTTP.post<HttpResponse<string>>(stringURL, stringRequest, this._objectHTTPOptions).pipe
			(
				map
				(
					modelHTTPResponse =>
					{
						const modelResponse: ResponseModel = new ResponseModel();

						if (modelHTTPResponse.body != null)
						{
							modelResponse.setModelFromString(atob(modelHTTPResponse.body));

							const stringAuthorizedKey: string | null = modelHTTPResponse.headers.get(StringConstant.STRING_HTTP_HEADER_AUTHORIZEDKEY);

							if (stringAuthorizedKey != null)
							{
								return interfaceHandshakeService.success(modelResponse, stringAuthorizedKey);
							}
							else
							{
								return interfaceHandshakeService.failNonObservable(modelResponse);
							}
						}
						else
						{
							return interfaceHandshakeService.failNonObservable(modelResponse);
						}
					}
				),
				catchError
				(
					(error: { status: ENUM_HTTP_STATUS; error: string; message: string }) =>
					{
						const modelResponse: ResponseModel = new ResponseModel();

						if (error.status === ENUM_HTTP_STATUS.NotFound || error.status === ENUM_HTTP_STATUS.BlockedByCORS)
						{
							modelResponse.MessageTitle = (error.status).toString();
							modelResponse.MessageContent = error.message;
							modelResponse.State = ENUM_RESPONSE_STATE.Fail;

							return interfaceHandshakeService.fail(modelResponse);
						}
						else if
						(
							error.status === ENUM_HTTP_STATUS.BadRequest ||
							error.status === ENUM_HTTP_STATUS.Unauthorized ||
							error.status === ENUM_HTTP_STATUS.NotAcceptable
						)
						{
							modelResponse.setModelFromString(atob(error.error));
							return interfaceHandshakeService.fail(modelResponse);
						}
						else if
						(
							error.status === ENUM_HTTP_STATUS.RequestEntityTooLarge ||
							error.status === ENUM_HTTP_STATUS.RequestHeaderFieldsTooLarge ||
							error.status === ENUM_HTTP_STATUS.RequestURITooLong
						)
						{
							modelResponse.setServiceTooLarge();
							return interfaceHandshakeService.fail(modelResponse);
						}
						else
						{
							modelResponse.setModelFromString(atob(error.error));
							return interfaceHandshakeService.fail(modelResponse);
						}
					}
				)
			);
		}
		catch (error: any)
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.setWebsiteServiceException(error.error);

			return interfaceHandshakeService.fail(modelResponse);
		}
	}

	/* callServiceHandshake - END */

	/*
		callServicePrivate - START
		Description : This function for call web service as unauthorized access, using unauthorized key.
		Author : Ibrahim Aziz.
		Created on : Thursday, 5 March 2020.			Updated on : Wednesday, 6 January 2021.
		Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
		Version : 1.0:4.
	*/

	protected callServicePrivate(interfaceGeneralService: GeneralServiceInterface, stringData: string, stringURL: string): void
	{
		if (this._modelHandshake.Token !== undefined && this._modelHandshake.Token.validateEmpty())
		{
			this._objectHTTPOptions =
			{
				headers: new HttpHeaders
				({
					"Content-Type": "application/json; charset=utf-8",
					// "X-Frame-Options" : "DENY",
					// eslint-disable-next-line quote-props
					"AuthorizedKey": this._modelHandshake.Token
				}),
				observe: "response",
				responseType: "text"
			};

			try
			{
				const modelRequest: RequestModel = new RequestModel();
				modelRequest.setRequest(this._modelUser.ID);
				modelRequest.Data = stringData;
				const stringRequest: string = JSON.stringify(modelRequest);

				if (this._modelHandshake.RequestKey !== undefined)
				{
					let stringRequestEncrypted: string = this._functionCryptography.encryptSymmetric(stringRequest, this._modelHandshake.RequestKey);
					stringRequestEncrypted = "\"" + stringRequestEncrypted + "\"";

					this._clientHTTP.post<HttpResponse<string>>(stringURL, stringRequestEncrypted, this._objectHTTPOptions).subscribe
					(
						modelHTTPResponse =>
						{
							const modelResponse: ResponseModel = new ResponseModel();

							if (this._modelHandshake.ResponseKey !== undefined)
							{
								if (modelHTTPResponse.body != null)
								{
									const stringResponseDecrypted: string = this._functionCryptography.decryptSymmetric(modelHTTPResponse.body, this._modelHandshake.ResponseKey);
									modelResponse.setModelFromString(stringResponseDecrypted);

									interfaceGeneralService.success(modelResponse);
								}
								else
								{
									modelResponse.setSessionExpired();

									interfaceGeneralService.fail(modelResponse);
								}
							}
							else
							{
								modelResponse.setSessionExpired();

								interfaceGeneralService.signOut(modelResponse);
							}
						},
						(error: { status: ENUM_HTTP_STATUS; error: any; message: string }) =>
						{
							const modelResponse: ResponseModel = new ResponseModel();

							if (error.status === ENUM_HTTP_STATUS.BadRequest)
							{
								modelResponse.setModelFromString(atob(error.error));
								modelResponse.State = ENUM_RESPONSE_STATE.Fail;

								interfaceGeneralService.fail(modelResponse);
							}
							else if (error.status === ENUM_HTTP_STATUS.NotFound || error.status === ENUM_HTTP_STATUS.BlockedByCORS)
							{
								modelResponse.MessageTitle = (error.status).toString();
								modelResponse.MessageContent = error.message;
								modelResponse.State = ENUM_RESPONSE_STATE.Fail;

								interfaceGeneralService.fail(modelResponse);
							}
							else if (error.status === ENUM_HTTP_STATUS.Unauthorized)
							{
								modelResponse.setModelFromString(atob(error.error));
								modelResponse.State = ENUM_RESPONSE_STATE.Fail;

								interfaceGeneralService.signOut(modelResponse);
							}
							else if
							(
								error.status === ENUM_HTTP_STATUS.RequestEntityTooLarge ||
								error.status === ENUM_HTTP_STATUS.RequestHeaderFieldsTooLarge ||
								error.status === ENUM_HTTP_STATUS.RequestURITooLong
							)
							{
								modelResponse.setServiceTooLarge();
								interfaceGeneralService.fail(modelResponse);
							}
							else if
							(
								error.status === ENUM_HTTP_STATUS.Forbidden ||
								error.status === ENUM_HTTP_STATUS.InternalServerError ||
								error.status === ENUM_HTTP_STATUS.NotAcceptable
							)
							{
								if (this._modelHandshake.ResponseKey !== undefined)
								{
									const stringResponseDecrypted: string = this._functionCryptography.decryptSymmetric(error.error, this._modelHandshake.ResponseKey);

									modelResponse.setModelFromString(stringResponseDecrypted);
									interfaceGeneralService.fail(modelResponse);
								}
								else
								{
									modelResponse.setSessionExpired();

									interfaceGeneralService.signOut(modelResponse);
								}
							}
							else
							{
								modelResponse.setSessionExpired();
								interfaceGeneralService.signOut(modelResponse);
							}
						}
					);
				}
				else
				{
					const modelResponse = new ResponseModel();
					modelResponse.setSessionExpired();

					interfaceGeneralService.fail(modelResponse);
				}
			}
			catch (error: any)
			{
				const modelResponse: ResponseModel = new ResponseModel();
				modelResponse.setWebsiteServiceException(error.error);

				interfaceGeneralService.fail(modelResponse);
			}
		}
		else
		{
			const modelResponse = new ResponseModel();
			modelResponse.setSessionExpired();

			interfaceGeneralService.fail(modelResponse);
		}
	}

	/* callServicePrivate - END */

	/*
		callServicePrivateForGuard - START
		Description : This function for call web service as unauthorized access, using unauthorized key.
		Author : Ibrahim Aziz.
		Created on : Thursday, 5 March 2020.			Updated on : Wednesday, 6 January 2021.
		Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
		Version : 1.0:5.
		References : https://www.freakyjolly.com/angular-7-6-use-auth-guards-canactivate-and-resolve-in-angular-routing-quick-example/.
	*/

	protected callServicePrivateForGuard(interfaceGuardService: GuardServiceInterface, stringData: string, stringURL: string): Observable<boolean>
	{
		if (this._modelHandshake.Token !== undefined && this._modelHandshake.Token.validateEmpty())
		{
			this._objectHTTPOptions =
			{
				headers: new HttpHeaders
				({
					"Content-Type": "application/json; charset=utf-8",
					// "X-Frame-Options" : "DENY",
					// eslint-disable-next-line quote-props
					"AuthorizedKey": this._modelHandshake.Token
				}),
				observe: "response",
				responseType: "text"
			};

			try
			{
				const modelRequest: RequestModel = new RequestModel();
				modelRequest.setRequest(this._modelUser.ID);
				modelRequest.Data = stringData;
				const stringRequest: string = JSON.stringify(modelRequest);

				if (this._modelHandshake.RequestKey !== undefined)
				{
					let stringRequestEncrypted: string = this._functionCryptography.encryptSymmetric(stringRequest, this._modelHandshake.RequestKey);
					stringRequestEncrypted = "\"" + stringRequestEncrypted + "\"";

					return this._clientHTTP.post<HttpResponse<string>>(stringURL, stringRequestEncrypted, this._objectHTTPOptions).pipe
					(
						map
						(
							modelHTTPResponse =>
							{
								const modelResponse: ResponseModel = new ResponseModel();

								if (this._modelHandshake.ResponseKey !== undefined)
								{
									if (modelHTTPResponse.body != null)
									{
										const stringResponseDecrypted: string = this._functionCryptography.decryptSymmetric(modelHTTPResponse.body, this._modelHandshake.ResponseKey);
										modelResponse.setModelFromString(stringResponseDecrypted);

										return interfaceGuardService.success(modelResponse);
									}
									else
									{
										modelResponse.setSessionExpired();

										return interfaceGuardService.failNonObservable(modelResponse);
									}
								}
								else
								{
									modelResponse.setSessionExpired();

									return interfaceGuardService.signOutNonObservable(modelResponse);
								}
							}
						),
						catchError
						(
							(error: { status: ENUM_HTTP_STATUS; error: any; message: string }) =>
							{
								const modelResponse: ResponseModel = new ResponseModel();

								if (error.status === ENUM_HTTP_STATUS.NotFound || error.status === ENUM_HTTP_STATUS.BlockedByCORS)
								{
									modelResponse.MessageTitle = (error.status).toString();
									modelResponse.MessageContent = error.message;
									modelResponse.State = ENUM_RESPONSE_STATE.Fail;

									return interfaceGuardService.fail(modelResponse);
								}
								else if (error.status === ENUM_HTTP_STATUS.BadRequest)
								{
									modelResponse.setModelFromString(atob(error.error));
									modelResponse.State = ENUM_RESPONSE_STATE.Fail;

									return interfaceGuardService.fail(modelResponse);
								}
								else if (error.status === ENUM_HTTP_STATUS.Unauthorized)
								{
									modelResponse.setModelFromString(atob(error.error));
									modelResponse.State = ENUM_RESPONSE_STATE.Fail;

									return interfaceGuardService.signOut(modelResponse);
								}
								else if
								(
									error.status === ENUM_HTTP_STATUS.RequestEntityTooLarge ||
									error.status === ENUM_HTTP_STATUS.RequestHeaderFieldsTooLarge ||
									error.status === ENUM_HTTP_STATUS.RequestURITooLong
								)
								{
									modelResponse.setServiceTooLarge();
									return interfaceGuardService.fail(modelResponse);
								}
								else if
								(
									error.status === ENUM_HTTP_STATUS.Forbidden ||
									error.status === ENUM_HTTP_STATUS.InternalServerError ||
									error.status === ENUM_HTTP_STATUS.NotAcceptable
								)
								{
									if (this._modelHandshake.ResponseKey !== undefined)
									{
										const stringResponseDecrypted: string = this._functionCryptography.decryptSymmetric(error.error, this._modelHandshake.ResponseKey);

										modelResponse.setModelFromString(stringResponseDecrypted);
										return interfaceGuardService.fail(modelResponse);
									}
									else
									{
										modelResponse.setSessionExpired();

										return interfaceGuardService.signOut(modelResponse);
									}
								}
								else
								{
									modelResponse.setSessionExpired();
									return interfaceGuardService.signOut(modelResponse);
								}
							}
						)
					);
				}
				else
				{
					const modelResponse = new ResponseModel();
					modelResponse.setSessionExpired();

					return interfaceGuardService.signOut(modelResponse);
				}
			}
			catch (error: any)
			{
				const modelResponse: ResponseModel = new ResponseModel();
				modelResponse.setWebsiteServiceException(error.error);

				return interfaceGuardService.fail(modelResponse);
			}
		}
		else
		{
			const modelResponse = new ResponseModel();
			modelResponse.setSessionExpired();

			return interfaceGuardService.fail(modelResponse);
		}
	}

	/* callServicePrivateForGuard - END */

	/*
		callServiceHandshakeRefresh - START
		Description : This function for call web service as unauthorized access, using previous key to refresh handshake.
		Author : Ibrahim Aziz.
		Created on : Friday, 6 March 2020.				Updated on : Wednesday, 6 January 2021.
		Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
		Version : 1.0:3.
		References : https://www.freakyjolly.com/angular-7-6-use-auth-guards-canactivate-and-resolve-in-angular-routing-quick-example/.
	*/

	protected callServiceHandshakeRefresh(interfaceHandshakeService: HandshakeServiceInterface, stringData: string, stringURL: string): Observable<boolean>
	{
		if (this._modelHandshake.Token !== undefined && this._modelHandshake.Token.validateEmpty())
		{
			this._objectHTTPOptions =
			{
				headers: new HttpHeaders
				({
					"Content-Type": "application/json; charset=utf-8",
					// "X-Frame-Options" : "DENY",
					// eslint-disable-next-line quote-props
					"AuthorizedKey": this._modelHandshake.Token
				}),
				observe: "response",
				responseType: "text"
			};

			try
			{
				const modelRequest: RequestModel = new RequestModel();
				modelRequest.setRequest(this._modelUser.ID);
				modelRequest.Data = stringData;
				const stringRequest: string = JSON.stringify(modelRequest);

				if (this._modelHandshake.RequestKey !== undefined)
				{
					let stringRequestEncrypted: string = this._functionCryptography.encryptSymmetric(stringRequest, this._modelHandshake.RequestKey);
					stringRequestEncrypted = "\"" + stringRequestEncrypted + "\"";

					return this._clientHTTP.post<HttpResponse<string>>(stringURL, stringRequestEncrypted, this._objectHTTPOptions).pipe
					(
						map
						(
							modelHTTPResponse =>
							{
								const modelResponse: ResponseModel = new ResponseModel();

								if (this._modelHandshake.ResponseKey !== undefined)
								{

								}
								else
								{
									modelResponse.setSessionExpired();

									return interfaceHandshakeService.signOutNonObservable(modelResponse);
								}

								if (modelHTTPResponse.body != null)
								{
									const stringResponseDecrypted: string = this._functionCryptography.decryptSymmetric(modelHTTPResponse.body, this._modelHandshake.ResponseKey);
									modelResponse.setModelFromString(stringResponseDecrypted);

									return interfaceHandshakeService.success(modelResponse, "");

								}
								else
								{
									modelResponse.setSessionExpired();

									return interfaceHandshakeService.failNonObservable(modelResponse);
								}
							}
						),
						catchError
						(
							(error: { status: ENUM_HTTP_STATUS; error: string; message: string }) =>
							{
								const modelResponse: ResponseModel = new ResponseModel();

								if (error.status === ENUM_HTTP_STATUS.NotFound || error.status === ENUM_HTTP_STATUS.BlockedByCORS)
								{
									modelResponse.MessageTitle = (error.status).toString();
									modelResponse.MessageContent = error.message;
									modelResponse.State = ENUM_RESPONSE_STATE.Fail;

									return interfaceHandshakeService.fail(modelResponse);
								}
								else if
								(
									error.status === ENUM_HTTP_STATUS.BadRequest ||
									error.status === ENUM_HTTP_STATUS.Unauthorized ||
									error.status === ENUM_HTTP_STATUS.NotAcceptable
								)
								{
									modelResponse.setModelFromString(atob(error.error));
									return interfaceHandshakeService.signOut(modelResponse);
								}
								else if
								(
									error.status === ENUM_HTTP_STATUS.RequestEntityTooLarge ||
									error.status === ENUM_HTTP_STATUS.RequestHeaderFieldsTooLarge ||
									error.status === ENUM_HTTP_STATUS.RequestURITooLong
								)
								{
									modelResponse.setServiceTooLarge();
									return interfaceHandshakeService.fail(modelResponse);
								}
								else
								{
									modelResponse.setModelFromString(atob(error.error));
									return interfaceHandshakeService.fail(modelResponse);
								}
							}
						)
					);
				}
				else
				{
					const modelResponse: ResponseModel = new ResponseModel();
					modelResponse.setSessionExpired();

					return interfaceHandshakeService.signOut(modelResponse);
				}
			}
			catch (error: any)
			{
				const modelResponse: ResponseModel = new ResponseModel();
				modelResponse.setWebsiteServiceException(error.error);

				return interfaceHandshakeService.fail(modelResponse);
			}
		}
		else
		{
			const modelResponse = new ResponseModel();
			modelResponse.setSessionExpired();

			return interfaceHandshakeService.signOut(modelResponse);
		}
	}

	/* callServiceHandshakeRefresh - END */

	//#endregion


	//#region DOWNLOAD

	/*
		downloadServicePrivate - START
		Description : This function for download document using web service, using handhsake token and document token.
		Author : Billy Johannes.
		Created on : Friday, 12 November 2021.			Updated on : Friday, 12 November 2021.
		Created by : Billy Johannes.					Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

	protected downloadServicePrivate(interfaceGeneralService: GeneralServiceInterface, stringDocumentToken: string, stringURL: string): void
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Download File.");

		if (this._modelUser.modelHandshake == null || this._modelUser.modelHandshake === undefined)
		{
			modelResponse.MessageContent = "Model Handshake empty! Please contact the developer!";
			interfaceGeneralService.fail(modelResponse);
		}
		else
		{
			if (this._modelUser.modelHandshake.Token !== undefined && this._modelUser.modelHandshake.Token.validateEmpty())
			{
				modelResponse.MessageContent = "Form is filled correctly!";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;

				stringURL = stringURL.replace("[HandshakeToken]",this._modelUser.modelHandshake.Token);
				stringURL = stringURL.replace("[DocumentToken]",stringDocumentToken);
				window.open(stringURL);
				interfaceGeneralService.success(modelResponse);
			}
			else
			{
				modelResponse.MessageContent = "Link or file does not found/exist!";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
				interfaceGeneralService.fail(modelResponse);
			}
		}
	}

	/* downloadServicePrivate - END */

	//#endregion
}

//#endregion