//#region IMPORT

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { AboutComponent } from "./components/about/about.component";
import { DownloadComponent } from "./components/download/download.component";
import { HomeComponent } from "./components/home/home.component";
import { SigninComponent } from "./components/signin/signin.component";
import { StringConstant } from "./constants/string.constant";
import { GeneralAuthorizedGuardService } from "./services/guards/generalauthorized.guard";
import { PublicGuardService } from "./services/guards/public.guard.service";

//#endregion


//#region DECLARATION

const arrayRoutes: Routes =
[
	{
		path: "",
		redirectTo: "/",
		pathMatch: "full"
	},
	{
		path: "",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin/downloadTicketAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "downloadTicketAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "home",
		redirectTo: "home/dashboard/dashboard",
	},
	{
		path: "home",
		component: HomeComponent,
		canActivate: [GeneralAuthorizedGuardService],
		children:
		[
			{
				path: "about",
				component: AboutComponent,
			},
			{
				path: "ticket",
				loadChildren: () => import("./components/tickets/tickets.module").then(pageModule => pageModule.TicketsModule)
			},
			{
				path: "dashboard",
				loadChildren: () => import("./components/dashboards/dashboard.module").then(pageModule => pageModule.DashboardModule)
			},
			{
				path: "ematerai",
				loadChildren: () => import("./components/ematerai/ematerai.module").then(pageModule => pageModule.EmateraiModule)
			},
			{
				path: "reimbursement",
				children:
				[
					{
						path: "approval",
						loadChildren: () => import("./components/approvalreimbursement/approval.module").then(pageModule => pageModule.ApprovalModule)
					},
				]
			},
			{
				path: "rcsa",
				children:
				[
					{
						path: "assessment",
						loadChildren: () => import("./components/rcsa/assessment/assessment.module").then(pageModule => pageModule.AssessmentModule)
					},
					{
						path: "approval",
						loadChildren: () => import("./components/rcsa/approval/approval.module").then(pageModule => pageModule.ApprovalModule)
					},
					{
						path: "report",
						loadChildren: () => import("./components/rcsa/report/report.module").then(pageModule => pageModule.ReportModule)
					},
					{
						path: "riskissuerequest",
						loadChildren: () => import("./components/rcsa/riskissuerequest/riskissuerequest.module").then(pageModule => pageModule.RiskissuerequestModule)
					},
				] ,
			},
			{
				path: "ras",
				children:
				[
					{
						path: "dashboard",
						loadChildren: () => import("./components/ras/dashboardras/dashboardras.module").then(pageModule => pageModule.DashboardrasModule)
					},
					{
						path: "followupplan",
						loadChildren: () => import("./components/ras/followupplan/followupplan.module").then(pageModule => pageModule.FollowupplanModule)
					},
				],
			},
			{
				path: "gcgonline",
				loadChildren: () => import("./components/gcgonline/gcgonline.module").then(pageModule => pageModule.GcgOnlineModule)
			},
			{
				path: "digitalization",
				loadChildren: () => import("./components/digitalization/submissions/submission.module").then(pageModule => pageModule.SubmissionModule)
			},
		]
	},
];

//#endregion


//#region MODULE

@NgModule
(
	{
		imports:
		[
			QuicklinkModule,
			RouterModule.forRoot(arrayRoutes,
				{
					preloadingStrategy: QuicklinkStrategy
				})
		],
		exports: [RouterModule]
	}
)

//#endregion


//#region CLASS

export class AppRoutingModule { }

//#endregion
