//#region IMPORT

import { StringConstant } from "src/app/constants/string.constant";

//#endregion


//#region CLASS

export class GeneralModel
{
	[key: string]: any;

	//#region SETTER

	/*
		setModelFromString - START
		Description : Setter from string data to specific model class.
		Author: Dimas Ari, Komang Suryadana and Ibrahim Aziz.
		Created on : Mon, 03 September 2018.          Updated on : Wednesday, 6 January 2021.
		Created by : Komang Suryadana.                Updated by : Ibrahim Aziz.
		Version : 0.1:2.
	*/

	setModelFromString(stringData: string): void
	{
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const objectData: { [key: string]: string } = JSON.parse(stringData);

		this.setModelFromObject(objectData);
	}

	/* setModelFromString - END */

	/*
		setCulture - START
		Description : Setter from string data to specific model class.
		Author: Ibrahim Aziz.
		Created on : Wednesday, 10 June 2020.			Updated on : Wednesday, 10 June 2020.
		Created by : Ibrahim Aziz.                		Updated by : Ibrahim Aziz.
		Version : 1.1:1.
	*/

	setCulture(): string
	{
		if (navigator.language === StringConstant.STRING_CULTURE_INDONESIAN)
		{
			return StringConstant.STRING_CULTURE_INDONESIAN;
		}
		else
		{
			return StringConstant.STRING_CULTURE_ENGLISH;
		}
	}

	/* setCulture - END */

	/*
	setModelFromObject - START
	Description : Setter from object data to specific model class.
	Author: Dimas Ari.
	Created on : Mon, 03 September 2018.          Updated on : Mon, 03 September 2018.
	Created by : Komang Suryadana.                Updated by : Komang Suryadana.
	Version : 0.1:1.
   */

	setModelFromObject(objectData: { [stringKey: string]: string }): void
	{
		for (const stringKey in objectData)
		{
			if (objectData.hasOwnProperty(stringKey))
			{
				if (typeof this[stringKey] === "undefined")
				{
					this[stringKey] = objectData[stringKey];
				}
				else if (this[stringKey] instanceof Date)
				{
					this[stringKey] = new Date(objectData[stringKey]);
				}
				/* else if (this[stringKey] instanceof DashboardAuditTrailModel)
				{
					this[stringKey] = new DashboardAuditTrailModel();
					(this[stringKey] as DashboardAuditTrailModel).setModelFromObject(objectData[stringKey]);
				}
				else if (this[stringKey] instanceof AuditTrailModel)
				{
					this[stringKey] = new AuditTrailModel();
					(this[stringKey] as AuditTrailModel).setModelFromObject(objectData[stringKey]);
				}
				else if (this[stringKey] instanceof AuditTrailItemModel)
				{
					this[stringKey] = new AuditTrailItemModel();
					(this[stringKey] as AuditTrailItemModel).setModelFromObject(objectData[stringKey]);
				} */
				else if (this[stringKey] instanceof GeneralModel)
				{
					(this[stringKey]).setModelFromObject(objectData[stringKey]);
				}
				else
				{
					this[stringKey] = objectData[stringKey];
				}
			}
			else
			{

			}
		}
	}

	/* setModelFromObject - END */

	//#endregion
}

//#endregion