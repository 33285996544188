//#region IMPORT

import { Directive, HostListener } from "@angular/core";
import { environment } from "src/environments/environment";

//#endregion


//#region Directive

@Directive
(
	{
		selector: "[appCheckDevTools]"
	}
)

//#endregion


//#region CLASS

export class CheckDevToolsDirective
{

	//#region HOSTLISTENER

	@HostListener("window:load", ["$event"])
	onLoad(): void
	{
		this.detectDevTool();
	}

	@HostListener("window:resize", ["$event"])
	onResize(): void
	{
		this.detectDevTool();
	}

	@HostListener("document:mousemove", ["$event"])
	onMouseMove(): void
	{
		this.detectDevTool();
	}

	@HostListener("window:focus", ["$event"])
	onFocus(): void
	{
		this.detectDevTool();
	}

	@HostListener("window:blur", ["$event"])
	onBlur(): void
	{
		this.detectDevTool();
	}

	@HostListener("contextmenu", ["$event"])
	onRightClick(event: MouseEvent): void
	{
		this.preventRightClick(event);
	}

	//#endregion


	//#region FUNCTION

	private detectDevTool(): void
	{
		if (environment.production === true)
		{
			const numberAllow = 100;
			const numberDateStart = +new Date(); // Validation of built-in Object tamper prevention.

			// this make debugger
			(() => { }).constructor("debugger;")();

			const numberDateEnd = +new Date(); // Validates too.
			if (Number.isNaN(numberDateStart) || Number.isNaN(numberDateEnd) || numberDateEnd - numberDateStart > numberAllow)
			{
				alert("devtools are opened!");
			}
			else
			{
			}
		}
		else
		{
		}
	}

	private preventRightClick(event: MouseEvent): void
	{
		if (environment.production === true)
		{
			event.preventDefault();
		}
		else
		{

		}
	}

	//#endregion
}

//#endregion