//#region IMPORT

import { Component, OnInit } from "@angular/core";
import { StringConstant } from "src/app/constants/string.constant";
import { environment } from "src/environments/environment";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-about",
		templateUrl: "./about.component.html",
		styleUrls: ["./about.component.sass"]
	}
)

//#endregion


//#region CLASS

export class AboutComponent implements OnInit
{
	//#region DECLARATION

	public _enumStringConstant = StringConstant;
	public _environmentBuild = environment.BUILD;

	//#region CONSTRUCTUR

	constructor()
	{

	}

	//#endregion


	//#region NAVIGATION

	ngOnInit(): void
	{

	}

	//#endregion

	//#region FUNCTION

	selectByEmailTSI(): void
	{
		window.location.href = "mailto:" + this._enumStringConstant.STRING_FIELD_EMAIL_TSI_BRINS ;
	}

	selectByTelephonePrimary(): void
	{
		window.location.href = "tel:" + this._enumStringConstant.STRING_VALUE_COUNTRYCODE_INDONESIA + this._enumStringConstant.STRING_VALUE_TELEPHONE_BRINS_PRIMARY;
	}

	selectByTelephoneSecondary(): void
	{
		window.location.href = "tel:" + this._enumStringConstant.STRING_VALUE_COUNTRYCODE_INDONESIA + this._enumStringConstant.STRING_VALUE_TELEPHONE_BRINS_SECONDARY;

	}

	selectByEmailInfo(): void
	{
		window.location.href = "mailto:" + this._enumStringConstant.STRING_FIELD_EMAIL_INFO_BRINS ;
	}

	selectByCallCenter(): void
	{
		window.location.href = "tel:" + this._enumStringConstant.STRING_VALUE_COUNTRYCODE_INDONESIA + this._enumStringConstant.STRING_VALUE_TELEPHONE_CALLCENTER;
	}

	//#endregion
}
//#endregion
