<div id="divBodySignIn" appCheckDevTools>
    <header id="headerSignIn">
        <h1>ACTUARIA</h1>

        <span id="spanSubTitle">Actuarial</span>
    </header>

    <form>
        <div class="DivForm TextCenter">
            <h3>Attachment will be downloaded in a minute, please wait.</h3>
        </div>
        <input type="button" value="Coba lagi" (click)="callDownload();">
        <input type="button" value="Kembali ke Halaman Utama" (click)="goToHome();">
    </form>
</div>