//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class UserGroupAccessModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for position model, used for link to division.
		Author: Eka Saputra.
		Created on : Thursday, 15 September 2022. 				Updated on : Thursday, 15 September 2022.
		Created by : Eka Saputra.								Updated by : Eka Saputra.
		Version : 1.0:0.
	*/

	ID?: number;
	UserID?: number;
	UserGroupID?: number;

	/* Attribute - END */
}

//#endregion